import React, { Component } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { CardHeader, ListItem, ListItemAvatar, ListItemText, List, Divider, ListItemSecondaryAction, Box } from '@mui/material';
import { AssignmentTurnedInOutlined, CreditCardOutlined, EmailOutlined, EventOutlined, HistoryOutlined, ReceiptOutlined } from '@mui/icons-material'
import DialogHelp from '../elements/DialogHelp';
import I18n from '../commons/Utils';
import { withTheme } from '@emotion/react';
import { Trans } from 'react-i18next';
import { t } from 'i18next';
import BrainoSnackbar from '../commons/BrainoSnackbar';
import { NavLink } from 'react-router-dom';
import api from '../services/api';

class AccountMain extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      helpOpen: false,
      detailsOpen: false,
      helpTitle: '',
      helpText: '',
      helpTitle: <Trans> {this.props.prefix} </Trans>,
      helpText: <I18n prefix={this.props.prefix} type="help"></I18n>,
      lang: localStorage.getItem('LANG'),
      notify1: false,
      notify2: false,
      isLight: true,

      snackOpen: false,
      snackMessage: ''
    }
  }

  async componentDidMount() {
    this.loadInfo()
  }

  closeHelpDialog() {
    this.setState({ helpOpen: false })
  }

  openHelpDialog() {
    this.setState({ helpOpen: true })
  }

  loadInfo() {
    
    api.get('subscription_info/' + localStorage.getItem('ID'))
      .then(response => {
        const data = response.data
        this.setState({ data })
      }).catch(error => {
        console.log(error);
      })
  }

  render() {
    const { data, snackOpen, snackMessage } = this.state;
    return (
      <Card variant="outlined">
        <DialogHelp
          closeHelpDialog={() => this.closeHelpDialog()}
          isHelpDialogOpen={this.state.helpOpen}
          helpDialogTitle={this.state.helpTitle}
          helpDialogContent={this.state.helpText}
          okButton="ok" />

        <BrainoSnackbar
          open={snackOpen}
          onClose={() => { this.setState({ snackOpen: false }) }}
          message={snackMessage}
        />

        <CardHeader
          title={<Typography gutterBottom variant="overline" component="h2">info</Typography>}
        ></CardHeader>
        <CardContent>
          <List>
            <ListItem>
              <ListItemAvatar><AssignmentTurnedInOutlined /></ListItemAvatar>
              <ListItemText primary={t('profile_account_label')} secondary={data.account_level}></ListItemText>
            </ListItem>
            <ListItem>
              <ListItemAvatar><CreditCardOutlined /></ListItemAvatar>
              <ListItemText
                primary={t('profile_payment_label')}
                secondary={
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    {data.payment_type ? data.payment_type : '-'}
                    {data.payment_type === "Credit card" && <Typography component={NavLink} to={"/editCard"} variant='body2' sx={{ ml: 1 }}> <Trans>profile_edit_card</Trans> </Typography>}
                  </Box>
                } />
            </ListItem>
            <ListItem>
              <ListItemAvatar><HistoryOutlined /></ListItemAvatar>
              <ListItemText primary={t('profile_last_payment_label')} secondary={data.last_payment ? data.last_payment : '-'}></ListItemText>
            </ListItem>
            <ListItem>
              <ListItemAvatar><EventOutlined /></ListItemAvatar>
              <ListItemText primary={t('profile_next_payment_label')} secondary={data.next_payment ? data.next_payment : '-'}></ListItemText>
            </ListItem>
            <ListItem>
              <ListItemAvatar><ReceiptOutlined /></ListItemAvatar>
              <ListItemText primary={t('profile_invoices_label')} secondary={<NavLink to='/invoices'>{t('profile_invoices_link_label')}</NavLink>}></ListItemText>
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemAvatar><EmailOutlined /></ListItemAvatar>
              <ListItemText primary='email' secondary={data.email}></ListItemText>
            </ListItem>
          </List>
        </CardContent>
      </Card>
    );
  }
}

export default withTheme(AccountMain);
